<template>

    <b-card
        id="view-login-form"
        :header="pageTitle"
        bg-variant="light"
        header-class="text-center"
        header-tag="h1"
        tag="form"
        no-body
        @submit.prevent="componentFormSubmit"
    >

        <b-card-body>

            <form-group
                id="view-login-email"
                v-slot="inputProps"
                :error="formModifiedModel.errors.email"
                :state="formModifiedModel.states.email"
                label="Adresse e-mail"
                left-icon="envelope"
                required
            >
                <b-form-input
                    v-model="formModifiedModel.$.email"
                    v-bind="inputProps"
                    autocomplete="username"
                    type="email"
                />
            </form-group>

            <form-group
                id="view-login-password"
                v-slot="inputProps"
                :error="formModifiedModel.errors.password"
                :state="formModifiedModel.states.password"
                label="Mot de passe"
                left-icon="key"
                required
            >
                <b-form-input
                    v-model="formModifiedModel.$.password"
                    v-bind="inputProps"
                    autocomplete="current-password"
                    type="password"
                />
            </form-group>

        </b-card-body>

        <b-card-footer class="d-flex justify-content-between">

            <b-button
                :to="{ name: 'forgot-password' }"
                class="mr-auto text-dark"
                variant="link"
            >
                <font-awesome-icon
                    :icon="['fad', 'question']"
                    fixed-width
                />
                Mot de passe inconnu ou oublié
            </b-button>

            <button-submit
                :is-pending="formIsPending"
                icon="house-return"
                icon-flip="horizontal"
            >
                Se connecter
            </button-submit>

        </b-card-footer>

    </b-card>

</template>



<script>

    import Csrf from '@core/models/auth/csrf';
    import Login from '@core/models/auth/login';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        data: () => ({
            formModifiedModel: new Login(),
        }),
        computed: {
            pageTitle: (vue) => `Se connecter à ${vue.platformName}`,
            platformName: (vue) => vue.$store.getters['center/platformName'],
        },
        methods: {
            componentFormSubmit() {
                new Csrf().request().show().then(() => this.formSubmit());
            },
            formSubmitSuccessful() {
                this.$router.push(this.$route.query.redirect || '/');
            },
        },
    };

</script>
