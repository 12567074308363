<template>

    <b-card
        id="view-forgot-password"
        bg-variant="light"
        tag="form"
        no-body
        @submit.prevent="formSubmit"
    >

        <b-card-header>

            <b-button
                :to="{ name: 'login-form' }"
                class="text-dark"
                size="sm"
                variant="link"
            >
                <font-awesome-icon
                    :icon="['fad', 'arrow-left']"
                    fixed-width
                />
                Retour
            </b-button>

            <h1 class="text-center">
                {{ pageTitle }}
            </h1>

        </b-card-header>

        <b-card-body>

            <b-alert
                variant="info"
                show
            >
                <font-awesome-icon
                    :icon="['fad', 'info']"
                    fixed-width
                />
                Entrez votre adresse e-mail et nous vous enverrons un lien pour récupérer votre compte.
            </b-alert>

            <form-group
                id="view-forgot-password-email"
                v-slot="inputProps"
                :error="formModifiedModel.errors.email"
                :state="formModifiedModel.states.email"
                label="Adresse e-mail"
                left-icon="envelope"
                required
            >
                <b-form-input
                    v-model="formModifiedModel.$.email"
                    v-bind="inputProps"
                    type="email"
                />
            </form-group>

        </b-card-body>

        <b-card-footer class="text-right">
            <button-submit
                :is-pending="formIsPending"
                icon="paper-plane"
            >
                Recevoir le lien par e-mail
            </button-submit>
        </b-card-footer>

    </b-card>

</template>



<script>

    import ForgotPassword from '@core/models/auth/forgot_password';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        data: () => ({
            formModifiedModel: new ForgotPassword(),
        }),
        computed: {
            pageTitle: () => 'Problèmes de connexion ?',
        },
        methods: {
            formSubmitSuccessful(message) {
                this.$root.toastSuccess('Consultez votre boîte de réception', message);
                this.$router.push({ name: 'login-form' });
            },
        },
    };

</script>
