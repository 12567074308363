import RestModel from '@core/models/_rest';

export default class ForgotPasswordAuth extends RestModel {

    /* eslint-disable-next-line class-methods-use-this */
    get url() {
        return '/auth/forgot-password';
    }

    /* eslint-disable-next-line class-methods-use-this */
    createSuccessful(response) {
        return response.data;
    }

}
