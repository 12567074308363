import RestModel from '@core/models/_rest';

export default class CsrfAuth extends RestModel {

    /* eslint-disable-next-line class-methods-use-this */
    get url() {
        return '/auth/csrf';
    }

}
