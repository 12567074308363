import ForgotPasswordView from '@core/views/ForgotPassword.vue';
import LayoutPlain from '@core/components/LayoutPlain.vue';
import LoginFormView from '@core/views/LoginForm.vue';
import ResetPasswordView from '@core/views/ResetPassword.vue';

export default [
    {
        path: '/',
        component: LayoutPlain,
        children: [
            {
                path: '/connexion',
                name: 'login-form',
                component: LoginFormView,
            },
            {
                path: '/mot-de-passe-inconnu-ou-oublie',
                name: 'forgot-password',
                component: ForgotPasswordView,
            },
            {
                path: '/nouveau-mot-de-passe/:token',
                name: 'reset-password',
                component: ResetPasswordView,
                props: true,
            },
        ],
    },
];
