<template>

    <b-card
        id="view-reset-password"
        bg-variant="light"
        tag="form"
        no-body
        @submit.prevent="formSubmit"
    >

        <b-card-header>

            <b-button
                :to="{ name: 'login-form' }"
                class="text-dark"
                size="sm"
                variant="link"
            >
                <font-awesome-icon
                    :icon="['fad', 'arrow-left']"
                    fixed-width
                />
                Connexion
            </b-button>

            <h1 class="m-0 text-center">
                {{ pageTitle }}
            </h1>

        </b-card-header>

        <b-card-body>

            <form-group
                id="view-reset-password-email"
                v-slot="inputProps"
                :error="formModifiedModel.errors.email"
                :state="formModifiedModel.states.email"
                label="Adresse e-mail"
                left-icon="envelope"
                required
            >
                <b-form-input
                    v-model="formModifiedModel.$.email"
                    v-bind="inputProps"
                    type="email"
                />
            </form-group>

            <form-group
                id="view-reset-password-password"
                v-slot="inputProps"
                :error="formModifiedModel.errors.password"
                :state="formModifiedModel.states.password"
                description="Au moins 12 caractères, incluant au moins une minuscule, une majuscule et un chiffre."
                label="Mot de passe"
                left-icon="key"
                required
            >
                <b-form-input
                    v-model="formModifiedModel.$.password"
                    v-bind="inputProps"
                    type="password"
                />
            </form-group>

            <form-group
                id="view-reset-password-password_confirmation"
                v-slot="inputProps"
                :error="formModifiedModel.errors.password_confirmation"
                :state="formModifiedModel.states.password_confirmation"
                label="Confirmer le mot de passe"
                left-icon="key"
                required
            >
                <b-form-input
                    v-model="formModifiedModel.$.password_confirmation"
                    v-bind="inputProps"
                    type="password"
                />
            </form-group>

        </b-card-body>

        <b-card-footer class="text-right">
            <button-submit
                :is-pending="formIsPending"
                icon="paper-plane"
            >
                Valider mon mot de passe
            </button-submit>
        </b-card-footer>

    </b-card>

</template>



<script>

    import ResetPassword from '@core/models/auth/reset_password';
    import Form from '@core/mixins/form';

    export default {
        mixins: [Form],
        props: {
            token: {
                type: String,
                required: true,
            },
        },
        computed: {
            pageTitle: () => 'Nouveau mot de passe',
        },
        watch: {
            token: {
                handler(token) {
                    this.formModifiedModel = new ResetPassword({ token });
                },
                immediate: true,
            },
        },
        methods: {
            formSubmitSuccessful(message) {
                this.$root.toastSuccess(message, "Vous pourrez l'utiliser dès votre prochaine connexion.");
                this.$router.push({ name: 'home-summary' });
            },
        },
    };

</script>
